/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.my-custom-modal-css .modal-wrapper {
    height: 85%;
    margin-top: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .sc-ion-modal-md-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
    
    .sc-ion-modal-ios-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
 }
 .sc-ion-modal-md-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.sc-ion-modal-ios-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.my-custom-class-prod .modal-wrapper{
    height: 55%;
    --background: #222;
    margin-top: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .sc-ion-modal-md-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
    
    .sc-ion-modal-ios-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
 }
 .sc-ion-modal-md-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.my-custom-class-trans .modal-wrapper{
    height: 65%;
    --background: #222;
    margin-top: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .sc-ion-modal-md-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
    
    .sc-ion-modal-ios-h:last-of-type {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    }
 }
 .sc-ion-modal-md-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}
.sc-ion-modal-ios-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);

  }
